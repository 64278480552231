// SITE

$site-width: 960px;
$site-gutter: 7.5%;

$site-border: 4rem;
$site-margin: 4rem;


$site-max-1: 140rem;
$site-max-2: 135rem;
$site-max-3: 52rem;
$site-max-4: 52rem;

// BREAKPOINTS

$breakpoint-1: 400px;
$breakpoint-2: 520px;
$breakpoint-3: 640px;
$breakpoint-4: 720px;
$breakpoint-5: 960px;
$breakpoint-6: 1140px;
$breakpoint-7: 1380px;


// COLORS

$brand-black: #000;
$brand-white: #fff;

$brand-offblack: #444;
$brand-darkgrey: #323232;
$brand-medgrey: #bbb;
$brand-offwhite: #eee;

$brand-red: #E50B66;
$brand-green: #1B835B;
$brand-blue: #4B19D8;