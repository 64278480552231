html, body, div, header, footer, main, section, nav, h1, h2, h3, h4, h5, h6, img, svg, p, a, span, ol, ul, li, form, input, label {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: none;
  margin: 0;
  padding: 0;
}

*, :before, :after {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

svg:not(:root) {
  overflow: hidden;
}

input {
  border-radius: 0;
}

::placeholder {
  opacity: 1;
}

a {
  color: inherit;
  -webkit-tap-highlight-color: #0000;
  background-color: #0000;
  outline: none;
  text-decoration: none;
}

html {
  font-size: 10px;
}

body {
  color: #fff;
  -webkit-user-select: none;
  user-select: none;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  background-color: #000;
  line-height: 1.3;
  position: relative;
}

.link {
  cursor: pointer;
}

@font-face {
  font-family: texta;
  src: url("assets/fonts/texta/texta-narrow-black.woff2") format("woff2");
  font-display: swap;
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: texta;
  src: url("assets/fonts/texta/texta-narrow-heavy.woff2") format("woff2");
  font-display: swap;
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: texta;
  src: url("assets/fonts/texta/texta-narrow-bold.woff2") format("woff2");
  font-display: swap;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: texta;
  src: url("assets/fonts/texta/texta-narrow-medium.woff2") format("woff2");
  font-display: swap;
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: texta;
  src: url("assets/fonts/texta/texta-narrow-regular.woff2") format("woff2");
  font-display: swap;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: texta;
  src: url("assets/fonts/texta/texta-narrow-book.woff2") format("woff2");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: texta;
  src: url("assets/fonts/texta/texta-narrow-light.woff2") format("woff2");
  font-display: swap;
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: texta;
  src: url("assets/fonts/texta/texta-narrow-thin.woff2") format("woff2");
  font-display: swap;
  font-weight: 200;
  font-style: normal;
}

.texta, .page.gallery .body, .page.gallery .header, .page.about .body, .page.about .header, .page.home .body, .page.home .header, body {
  font-family: texta;
  font-weight: 400;
  line-height: 1.2em;
}

.texta .headline-text, .page.gallery .body .headline-text, .texta .page.gallery .header, .page.gallery .texta .header, .page.gallery .body .header, .page.gallery .header .headline-text, .page.gallery .header .header, .page.about .body .headline-text, .texta .page.about .header, .page.about .texta .header, .page.about .body .header, .page.about .header .headline-text, .page.about .header .header, .page.home .body .headline-text, .texta .page.home .header, .page.home .texta .header, .page.home .body .header, .page.home .header .headline-text, .page.home .header .header, body .headline-text, body .page.gallery .header, .page.gallery body .header, body .page.about .header, .page.about body .header, body .page.home .header, .page.home body .header {
  font-size: 3em;
}

.texta .body-text, .texta .page.gallery .body, .page.gallery .texta .body, .page.gallery .body .body-text, .page.gallery .body .body, .page.gallery .header .body-text, .page.gallery .header .body, .texta .page.about .body, .page.about .texta .body, .page.about .body .body-text, .page.about .body .body, .page.about .header .body-text, .page.about .header .body, .texta .page.home .body, .page.home .texta .body, .page.home .body .body-text, .page.home .body .body, .page.home .header .body-text, .page.home .header .body, body .body-text, body .page.gallery .body, .page.gallery body .body, body .page.about .body, .page.about body .body, body .page.home .body, .page.home body .body {
  margin-bottom: 2rem;
  font-size: 1.2em;
  line-height: 1.8em;
}

em {
  font-style: italic;
}

body {
  width: 100%;
  font-size: 1em;
  overflow: hidden;
}

.container {
  height: calc(var(--vh, 1vh) * 100);
  min-height: calc(var(--vh, 1vh) * 100);
  max-height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  position: relative;
  overflow-y: scroll;
}

.container-inner {
  min-height: calc(var(--vh, 1vh) * 100);
  opacity: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.container-inner.hidden {
  opacity: 0;
}

.page {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
}

.buttons-row {
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  display: flex;
}

img, svg, video, iframe {
  width: 100%;
  height: auto;
  display: block;
}

div {
  background-repeat: no-repeat;
  background-size: contain;
}

img:not([src]) {
  visibility: hidden;
}

iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.video-container {
  position: relative;
}

body {
  position: fixed;
  overflow: hidden;
}

.page-wrap, .page.gallery .wrap, .page.about .wrap, .page.home .wrap {
  width: 90%;
  max-width: 80rem;
  padding: 4rem;
}

.button {
  cursor: pointer;
  color: #000;
  background-color: #fff;
  flex-grow: 1;
  margin-right: 1rem;
  padding: 1rem;
}

.button:last-child {
  margin-right: 0;
}

.button:hover {
  color: #fff;
  background-color: #000;
}

.page.home .wrap {
  background-color: #e50b66;
}

.page.home .header, .page.home .body {
  margin-bottom: 2rem;
}

.page.about .wrap {
  background-color: #1b835b;
}

.page.about .header, .page.about .body {
  margin-bottom: 2rem;
}

.page.gallery .wrap {
  background-color: #4b19d8;
}

.page.gallery .header, .page.gallery .body {
  margin-bottom: 2rem;
}

.page.gallery .body .media-container {
  background-color: #333;
  margin-bottom: 5px;
}

/*# sourceMappingURL=index.90ff72b8.css.map */
