// - - - Texta Narrow - - - //

/*

BLACK        -   900
HEAVY        -   800
BOLD         -   700
MEDIUM       -   600
REGULAR      -   500
BOOK         -   400
LIGHT        -   300
THIN         -   200

*/

@font-face {
    font-family: 'texta';
    src: url('./assets/fonts/texta/texta-narrow-black.woff2') format('woff2');
    font-display: swap;
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'texta';
    src: url('./assets/fonts/texta/texta-narrow-heavy.woff2') format('woff2');
    font-display: swap;
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'texta';
    src: url('./assets/fonts/texta/texta-narrow-bold.woff2') format('woff2');
    font-display: swap;
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'texta';
    src: url('./assets/fonts/texta/texta-narrow-medium.woff2') format('woff2');
    font-display: swap;
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'texta';
    src: url('./assets/fonts/texta/texta-narrow-regular.woff2') format('woff2');
    font-display: swap;
    font-weight: 500;
    font-style: normal;
}


@font-face {
    font-family: 'texta';
    src: url('./assets/fonts/texta/texta-narrow-book.woff2') format('woff2');
    font-display: swap;
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'texta';
    src: url('./assets/fonts/texta/texta-narrow-light.woff2') format('woff2');
    font-display: swap;
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'texta';
    src: url('./assets/fonts/texta/texta-narrow-thin.woff2') format('woff2');
    font-display: swap;
    font-weight: 200;
    font-style: normal;
}