.texta {

    font-family: 'texta';
    font-weight: 400;
    line-height: 1.2em;

    .headline-text {
        font-size: 3em;
    }

    .body-text {
        font-size: 1.2em;
        line-height: 1.8em;
        margin-bottom: 2rem;
    }
}


em {
    font-style: italic;
}



body {
    font-size: 1em;
}

@media screen and (min-width: $breakpoint-1 ) {}

@media screen and (min-width: $breakpoint-2 ) {}

@media screen and (min-width: $breakpoint-3 ) {}

@media screen and (min-width: $breakpoint-4 ) {}

@media screen and (min-width: $breakpoint-5 ) {}

@media screen and (min-width: $breakpoint-6 ) {}

@media screen and (min-width: $breakpoint-7 ) {}