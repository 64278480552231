.button {
    cursor: pointer;
    padding: 1rem;
    background-color: $brand-white;
    color: $brand-black;
    flex-grow: 1;
    margin-right: 1rem;

    &:last-child {
        margin-right: 0;
    }

    &:hover {
        background-color: $brand-black;
        color: $brand-white;
    }
}