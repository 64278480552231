// FONT

html {
    // 1rem = 10px
    font-size: 10px; // 1rem = 10px
}

body {
    @extend .texta;
    line-height: 1.3;
    background-color: $brand-black;
    color: $brand-white;
    position: relative;

    user-select: none;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
}

.link {
    cursor: pointer;
}