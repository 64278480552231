body {
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
}

.container {
    width: 100%;
    position: relative;
    height: calc(var(--vh, 1vh) * 100);
    min-height: calc(var(--vh, 1vh) * 100);
    max-height: calc(var(--vh, 1vh) * 100);

    overflow-y: scroll;
}

.container-inner {

    position: absolute;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    width: 100%;
    min-height: calc(var(--vh, 1vh) * 100);

    // height: calc(var(--vh, 1vh) * 100);
    // min-height: calc(var(--vh, 1vh) * 100);
    // max-height: calc(var(--vh, 1vh) * 100);

    // overflow-y: scroll;

    top: 0;
    left: 0;
    opacity: 1;

    &.hidden {
        opacity: 0;
    }

}

// body.static {
//     .container-inner.hidden {
//         opacity: 1;
//     }
// }

.page {
    width: 100%;

    margin-top: auto;
    margin-bottom: auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.buttons-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
}

img,
svg,
video,
iframe {
    width: 100%;
    height: auto;
    display: block;
}

div {
    background-repeat: no-repeat;
    background-size: contain;
}

img:not([src]) {
    visibility: hidden;
}

iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.video-container {
    position: relative;
}

body {
    overflow: hidden;
    position: fixed;
}

.page-wrap {
    width: 90%;
    max-width: 80rem;
    padding: 4rem;
}

@media screen and (min-width: $breakpoint-1 ) {}

@media screen and (min-width: $breakpoint-2 ) {}

@media screen and (min-width: $breakpoint-3 ) {}

@media screen and (min-width: $breakpoint-4 ) {}

@media screen and (min-width: $breakpoint-5 ) {}

@media screen and (min-width: $breakpoint-6 ) {}

@media screen and (min-width: $breakpoint-7 ) {}