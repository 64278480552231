.page.home {

    .wrap {
        @extend .page-wrap;
        background-color: $brand-red;
    }

    .header {
        @extend .texta;
        @extend .headline-text;
        margin-bottom: 2rem;
    }

    .body {
        @extend .texta;
        @extend .body-text;
        margin-bottom: 2rem;
    }

}

@media screen and (min-width: $breakpoint-1 ) {}

@media screen and (min-width: $breakpoint-2 ) {}

@media screen and (min-width: $breakpoint-3 ) {}

@media screen and (min-width: $breakpoint-4 ) {}

@media screen and (min-width: $breakpoint-5 ) {}

@media screen and (min-width: $breakpoint-6 ) {}

@media screen and (min-width: $breakpoint-7 ) {}